import {
  ResultComponent,
  SEOComponent,
  ContainerComponent,
  FooterComponent,
  Br,
} from '@/components';

const NotFound = () => (
  <>
    <SEOComponent screenName="top" noIndex />
    <main>
      <ContainerComponent className="py-10 tl:py-12">
        <ResultComponent
          title="お探しのページが見つかりません"
          description={
            <>
              申し訳ございません。
              <Br />
              ご覧になっていたページからのリンクが無効になっているか、
              <Br />
              削除された可能性があります。
            </>
          }
        />
      </ContainerComponent>
    </main>
    <FooterComponent isInInquiryScreen />
  </>
);

export default NotFound;
